<template></template>

<script>
export default {
  name: "landing",
  mounted() {
    this.$router.push('/register');
  }
};
</script>

<style lang="scss" scoped>
</style>
